import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Usuario, Usuario2, DatosUsuario, Usuario3 } from '../../models/usuario.model';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';


@Injectable()
export class UsuarioService {

  token: string;
  idUsuario: string;
  path: string =  environment.URL_SERVICIOS.replace('/api/v1','');
  // this.path =  environment.URL_SERVICIOS.replace('/api/v1','')
  public datosusuario: DatosUsuario
  constructor(
    public http: HttpClient,
    public router: Router
  ) {
    this.cargarStorage();
  }

  estaLogueado() {
   let stringExpiraToken = localStorage.getItem('expiratoken');
   if (stringExpiraToken !== null) {
    stringExpiraToken = stringExpiraToken.substring(0, 10);
   const fechaExpiraToken = Date.parse(stringExpiraToken);
   const fechaHoy = new Date();
   const fechaHoyA = fechaHoy.getFullYear().toString();
   let fechaHoyM = (fechaHoy.getMonth() + 1).toString();
   let fechaHoyD = fechaHoy.getDate().toString();

   if ( fechaHoyM.length === 1 ) {
    fechaHoyM = ( '0' + fechaHoyM );
   }

   if ( fechaHoyD.length === 1 ) {
    fechaHoyD = ( '0' + fechaHoyD );
   }

   const fechaHoyC = Date.parse(fechaHoyA + '-' + fechaHoyM + '-' + fechaHoyD);

   
    if ( fechaExpiraToken > fechaHoyC ) {
      this.getusuario(localStorage.getItem('uid')).subscribe( resp => {
        this.datosusuario = new DatosUsuario(resp[0].id,'','','','');
      } )
      return true;
    }
   else {
      localStorage.clear();
      return false;
  }
   } else {
    localStorage.clear();
    return false
   }
   
}

  cargarStorage() {

    if ( localStorage.getItem('tkn')) {
      this.token = localStorage.getItem('tkn');
    } else {
      this.token = '';
    }

  }

  logout() {
    localStorage.clear();
  }

  getCustomerxidu(idu: string) {
    const url = `${environment.URL_SERVICIOS}/reports/user_id/${idu}/customer_user_association?token=${this.token}&secret_key=${environment.SECRET_KEY}`;
    return this.http.get( url )
                .map( (resp: any) => {
                  return resp;
                });
  }

  guardarStoragelogin( id: string, token: string, email: string,name: string, expiratoken: string, roleid: string ) {

    localStorage.setItem('uid', id );
    localStorage.setItem('tkn', token );
    localStorage.setItem('emailuser', email );
    localStorage.setItem('nombreuser', name );
    localStorage.setItem('expiratoken', expiratoken);
    localStorage.setItem('rid', roleid);
   // localStorage.setItem('user_options', JSON.stringify(relations));

    this.token = token;
  }

  login( correo, pass ) {
    const url = `${environment.URL_SERVICIOS}/authenticate?auth[email]=${correo}&auth[password]=${pass}&secret_key=${environment.SECRET_KEY}`;
    return this.http.get( url )
                .map( (resp: any) => {
                  return resp;
                });
  }

  crearArregloData( Obj: any) {
    const arr: any[] = [];
    const resul: any[] = [];
    if ( Obj === null ) { return []; }
    Object.keys ( Obj ).forEach( key => {
      const fo: any = Obj[key];
      arr.push( fo );
    });
    resul.push( arr[0].attributes );
    return resul;
}

  getusuario( idu ) {
    const url = `${environment.URL_SERVICIOS}/users/${idu}?token=${this.token}&secret_key=${environment.SECRET_KEY}`
    return this.http.get( url ).pipe(
      map( (resp: any) => {return this.crearArregloData(resp);
      }));
  }

  getusuarioById( idu ) {
    const url = `${environment.URL_SERVICIOS}/users/${idu}?token=${this.token}&secret_key=${environment.SECRET_KEY}`
    return this.http.get( url ).pipe(
      map( (resp: any) => {return resp;
      }));
  }

  getInfoById( idu ) {
    const url = `${environment.URL_SERVICIOS}/get_user_customer?user_id=${idu}&token=${this.token}&secret_key=${environment.SECRET_KEY}`
    return this.http.get( url ).pipe(
      map( (resp: any) => {return resp;
      }));
  }

  getBuroById( idu ) {
    const url = `${environment.URL_SERVICIOS}/user_buro_validation?user_id=${idu}&token=${this.token}&secret_key=${environment.SECRET_KEY}`
    return this.http.get( url ).pipe(
      map( (resp: any) => {return resp;
      }));
  }

  getUserDoctor( idu ) {
    const url = `${environment.URL_SERVICIOS}/reports/get_user_doctor?token=${this.token}&secret_key=${environment.SECRET_KEY}&user_id=${idu}`
    return this.http.get( url ).pipe(
      map( (resp: any) => {return resp;
      }));
  }

  getrol(idr) {
    const url = `${environment.URL_SERVICIOS}/roles/${idr}?token=${this.token}&secret_key=${environment.SECRET_KEY}`
    return this.http.get( url ).pipe(
      map( (resp: any) => {return resp;
      }));
  }

  getUsuarios() {

    const url = `${environment.URL_SERVICIOS}/users?secret_key=${environment.SECRET_KEY}&token=${this.token}`;

    return this.http.get( url ).pipe(
    map( (resp: any) => {return this.crearArregloUsuarios(resp);
    }));

    }

  getUsuariosVendedores() {

    const url = `${environment.URL_SERVICIOS}/reports/get_user_vendedor?secret_key=${environment.SECRET_KEY}&token=${this.token}`;

    return this.http.get( url ).pipe(
    map( (resp: any) => {return resp;
    }));

    }

  borrarUsuario(id: string) {

    const params = {
      token: this.token,
      secret_key: environment.SECRET_KEY
    }
  
    const url = `${ environment.URL_SERVICIOS }/users/${ id }?`;
  
    return this.http.delete( url, {params} ).pipe(
      map( (resp: any) => { return resp;
        } ));
  
  }

  getCustomer( idu ) {
    const url = `${environment.URL_SERVICIOS}/users/${idu}?token=${this.token}&secret_key=${environment.SECRET_KEY}`
    return this.http.get(url);
    // return this.http.get( url ).pipe(
    //   map( (resp: any) => {return resp;
    //   }));
  }

    crearArregloUsuarios( usuariosObj: any) {

      const usuarios: any[] = [];
      const resul: any[] = [];
  
      if ( usuariosObj === null ) { return []; }
      Object.keys ( usuariosObj ).forEach( key => {
        const usuario: any = usuariosObj[key];
        usuarios.push( usuario );
      });
      // tslint:disable-next-line: forin
      for (const prop in usuarios[0]) {
    //  console.log( usuarios[0][prop].attributes );
      resul.push( usuarios[0][prop].attributes );
      }
  
    //  console.log(resul);
  
      return resul;
  
  }

  actualizaUsuario(id, params) {
    params.token = this.token;
    params.secret_key = environment.SECRET_KEY;
    const url = `${environment.URL_SERVICIOS}/users/${id}?auth[job]=${params.job}&token=${this.token}&secret_key=${environment.SECRET_KEY}&auth[email]=${params.email}&auth[gender]=${params.gender}&auth[status]=${params.status}&auth[name]=${params.name}&auth[role_id]=${params.role_id}&auth[company_signatory]=${params.company_signatory}`;
    return this.http.patch( url, null ).pipe(
      map( (resp: any) => { return resp;
      } ));
    }

  actualizaGeneralUsuario(id, params) {
    params.token = this.token;
    params.secret_key = environment.SECRET_KEY;
    const url = `${environment.URL_SERVICIOS}/users/${id}?&token=${this.token}&secret_key=${environment.SECRET_KEY}`;
    return this.http.patch( url, params ).pipe(
      map( (resp: any) => { return resp;
      } ));
    }

    actualizaUsuarioModel(usuario: Usuario3) {

      const url = `${environment.URL_SERVICIOS}/users/${usuario.id}?token=${this.token}&secret_key=${environment.SECRET_KEY}&auth[job]=${usuario.puesto}&auth[status]=${usuario.estatus}&auth[gender]=${usuario.genero}&auth[email]=${usuario.email}&auth[name]=${usuario.nombre}`;
      
      return this.http.patch( url, null ).pipe(
        map( (resp: any) => { return resp;
        } ));
      }

  actualizaUsuarioStatus(id, params) {
      params.secret_key = environment.SECRET_KEY;
      console.log(params);
      console.log(id)
      const url = `${environment.URL_SERVICIOS}/users/${id}?secret_key=${environment.SECRET_KEY}&auth[status]=${params.status}`;
      
      return this.http.patch( url, null ).pipe(
        map( (resp: any) => { return resp;
        } ));
      }  

  getUsuarioOptions( id: string ) {

      const url = `${environment.URL_SERVICIOS}/users/${id}?secret_key=${environment.SECRET_KEY}&token=${this.token}`;
    
      return this.http.get( url ).pipe(
        map ( (resp: any) => { return this.crearArregloUsuarioOptions(resp);
        } ));
    
    }

    crearArregloUsuarioOptions( usuariosObj: any) {

      const usuarios: any[] = [];
      const resul1: any[] = [];
      const resul2: any[] = [];
      if ( usuariosObj === null ) { return []; }
    
      // tslint:disable-next-line: forin
      for (const prop in usuariosObj.data.relations.options) {
        //  resul.push( usuariosObj.data.relations.options[prop].attributes );
        resul1.push (usuariosObj.data.relations.options[prop].attributes);
        resul2.push (usuariosObj.data.relations.user_options[prop].attributes);
    
          }
      // tslint:disable-next-line: forin
      for (const prop in resul2) {
    
        const iduo = resul2[prop].option_id;
    
          // tslint:disable-next-line: forin
        for (const prap in resul1) {
    
          const ido = resul1[prap].id;
    
          if ( iduo === ido ) {
    
            resul2[prop].name = resul1[prap].name;
    
          }
    
    
          }
    
      }
      return resul2;
    
    }

    getUserGender() {
      const params = new HttpParams () 
      .set ('token', this.token)
      .set ('secret_key', environment.SECRET_KEY); 
      const url = `${environment.URL_SERVICIOS}/lists/domain/USER_GENDER?secret_key=${environment.SECRET_KEY}&token=${this.token}`;   
      return this.http.get(url).pipe(
        map( (resp: any) => {
          return this.crearArregloList(resp);
        } )
      );
    
    }
  
    getUserStatus() {
      const params = new HttpParams () 
      .set ('token', this.token)
      .set ('secret_key', environment.SECRET_KEY); 
      const url = `${environment.URL_SERVICIOS}/lists/domain/USER_STATUS?secret_key=${environment.SECRET_KEY}&token=${this.token}`;
      return this.http.get(url).pipe(
        map( (resp: any) => {
          return this.crearArregloList(resp);
        } )
      );
    
    }

    getUserStatus2() {
      const params = new HttpParams () 
      .set ('token', this.token)
      .set ('secret_key', environment.SECRET_KEY); 
      const url = `${environment.URL_SERVICIOS}/lists/domain/USER_STATUS?secret_key=${environment.SECRET_KEY}&token=${this.token}`;
      return this.http.get(url).pipe(
        map( (resp: any) => {
          return resp
        } )
      );
    
    }
    
    crearArregloList( contribuObj: any) {
    
      const rr: any[] = [];
      const resul: any[] = [];
    
      if ( contribuObj === null ) { return []; }
      Object.keys ( contribuObj ).forEach( key => {
        const rol: any = contribuObj[key];
        rr.push( rol );
      });
      // tslint:disable-next-line: forin
      for ( const prop in rr[0] ) {
    
        resul.push( rr[0][prop].attributes.value );
    
      }
    
      return resul;
    
    }

    crearUsuario( usuario: Usuario2 ) {

      const url = `${environment.URL_SERVICIOS}/users?token=${this.token}&secret_key=${environment.SECRET_KEY}`;
  
  
      return this.http.post( url, null ).pipe(
                map( (resp: any) => {
                  return resp;
                }));
    }

    registrarUsuario( params ) {

      const url = `${environment.URL_SERVICIOS}/users?token=${this.token}&secret_key=${environment.SECRET_KEY}`;
  
  
      return this.http.post( url, params ).pipe(
                map( (resp: any) => {
                  return resp;
                }));
    }

    actualizaUsuarioRcPc(idu, params) {
      params.token = this.token;
      params.secret_key = environment.SECRET_KEY;
  
      const url = `${environment.URL_SERVICIOS}/users/${idu}?token=${params.token}&secret_key=${environment.SECRET_KEY}&auth[password]=${params.password}`;
  
      return this.http.patch( url, null ).pipe(
        map( (resp: any) => { return resp;
        } ));
      }

    generarDocumentosDoctor(customer_credit_id) {
  
      const url = `${environment.URL_SERVICIOS}/generate_documents_doctor?customer_credit_id=${customer_credit_id}&token=${this.token}&secret_key=${environment.SECRET_KEY}`;
  
      return this.http.patch( url, null ).pipe(
        map( (resp: any) => { return resp;
        } ));
      }

      getUsuarioRPWT( rpwt ) {

        const url = `${environment.URL_SERVICIOS}/get_reset_token?secret_key=${environment.SECRET_KEY}&reset_password_token=${rpwt}`;
    
        return this.http.get( url ).pipe(
        map( (resp: any) => {return this.crearArregloUsuario(resp);
        }));
    
        }

        crearArregloUsuario( usuariosObj: any) {
          const usuarios: any[] = [];
          const resul: any[] = [];
          if ( usuariosObj === null ) { return []; }
          Object.keys ( usuariosObj ).forEach( key => {
            const usuario: any = usuariosObj[key];
            usuarios.push( usuario );
          });
          resul.push( usuariosObj.data.attributes );
          return resul;  
        }

    getResetPaswword(email) {
      const url = `${environment.URL_SERVICIOS}/reset_password?email=${email}&secret_key=${environment.SECRET_KEY}`;
      return this.http.get( url ).pipe(
        map ( (resp: any) => { return (resp);
            } ));
        }   
        
        
        getResetPasswordToken(rtoken) {
          const url = `${this.path}/get_reset_pwd_token?reset_password_token=${rtoken}&secret_key=${environment.SECRET_KEY}`;
          return this.http.get( url ).pipe(
            map ( (resp: any) => { return (resp);
            } ));
        }

        confirmCredit(rtoken) {
          const token = rtoken;
          const url = `${this.path}/get_callback?call_back_token=${rtoken}`;
          return this.http.post( url, token)
        }

        declineCredit(rtoken) {
          const token = rtoken;
          const url = `${this.path}/get_callback_decline?call_back_token=${rtoken}`;
          return this.http.post( url, token)
        }

        getTokenStatus( token ) 
        {
          const rtoken = token;
          const url = `${this.path}/get_callback_token?call_back_token=${token}`;
          return this.http.post( url, {}).pipe(
          map( (resp: any) => { return (resp); 
          }));
      
          }

        fileToken( token ) 
        {
          const rtoken = token;
          const url = `${this.path}/file_callback?call_back_token=${token}`;
          return this.http.post( url, rtoken).pipe(
          map( (resp: any) => { return (resp); 
          }));
      
          }

        getComiteeTokenStatus( token ) 
        {
          const rtoken = token;
          const url = `${this.path}/get_comitee_callback_token?call_back_token=${token}`;
          return this.http.post( url, rtoken).pipe(
          map( (resp: any) => { return (resp); 
          }));
      
          }

          actualizaUsuarioPassWord( password,token ) {
            const url = `${this.path}/update_user_pwd?&password=${password}&reset_password_token=${token}`;
            return this.http.patch( url, null ).pipe(
              map( (resp: any) => { return resp;
              } ));
            }

        actualizaUsuarioRc(idu, params) {

          params.secret_key = environment.SECRET_KEY;
        
          const url = `${environment.URL_SERVICIOS}/users/${idu}?token=${params.token}&secret_key=${environment.SECRET_KEY}&auth[password]=${params.password}`;
        
          return this.http.patch( url, null ).pipe(
            map( (resp: any) => { return resp;
            } ));
          }    
      async fillLS(resp: any) {
        try {
          localStorage.setItem('uid', resp.data.attributes.id );
          localStorage.setItem('tkn', resp.data.token );
          localStorage.setItem('emailuser', resp.data.attributes.email );
          localStorage.setItem('nombreuser', resp.data.attributes.name );
          localStorage.setItem('expiratoken', resp.data.expires_at);
          localStorage.setItem('rid', resp.data.attributes.role_id);
          return true
        } catch (error) {
          
        }
        
      }

      getNipStatus( nip,customer ) 
      {        
        const rnip = nip;
        const url = `${environment.URL_SERVICIOS}/credit_bureaus/get_credit_bureau_nip_validation?id=${customer}&nip=${nip}&secret_key=${environment.SECRET_KEY}&token=${this.token}`;
        console.log(url);
        return this.http.post( url, rnip).pipe(
        map( (resp: any) => { return (resp); 
        }));
    
        }
        
      resetNip( customerId ) 
      {        
        // console.log("YA ESTAMOS DENTRO DEL SERVICIO RESET")
        // console.log(customerId)
        const id= customerId
        const rnip = customerId;
        const url = `${environment.URL_SERVICIOS}/credit_bureaus/reset_credit_bureau_nip?id=${id}&secret_key=${environment.SECRET_KEY}&token=${this.token}`;
        return this.http.post( url, rnip).pipe(
        map( (resp: any) => { return (resp); 
        }));
    
        }

        send_client_registration_mailer( nombre,correo,empresa ) {
          const url = `${this.path}/send_client_registration_mailer?&nombre=${nombre}&correo=${correo}&empresa=${empresa}`;
          return this.http.post( url, null ).pipe(
            map( (resp: any) => { return resp;
            } ));
          }


      // ---------------------------------------------
      getUserDocumentsEndpoint(idu) {
        const url = `${environment.URL_SERVICIOS}/get_doctor_documents?user_id=${idu}&secret_key=${environment.SECRET_KEY}&token=${this.token}`;
        return this.http.get( url ).pipe(
          map ( (resp: any) => { return (resp);
          } ));
      }

      getUserDocuments(idu) {
        const url = `${environment.URL_SERVICIOS}/users/${idu}/user_documents?secret_key=${environment.SECRET_KEY}&token=${this.token}`;
        return this.http.get( url ).pipe(
          map ( (resp: any) => { return (resp);
          } ));
      }

      setUserDocuments(idu, idd, params) {
        const url = `${environment.URL_SERVICIOS}/users/${idu}/user_documents/${idd}?secret_key=${environment.SECRET_KEY}&token=${this.token}`;
        return this.http.patch( url, params ).pipe(
          map ( (resp: any) => { return (resp);
          } ));
      }
}
