import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ControlPanelComponent } from './controlpanel.component';
import { LoginGuardGuard, UsuariosPcGuard, ListasPcGuard, OpcionesPcGuard,
RolesPcGuard, ParametrosGeneralesPcGuard, OpcionesUsuariosPcGuard, RolesOpcionesPcGuard, 
PrivilegiosUsuariosPcGuard, ServiciosExternosPcGuard, TiposArchivosPcGuard, DocumentoPcGuard, CondicionesPcGuard,
PeriodosPagoPcGuard, CalificacionCreditosPcGuard, TarifasPcGuard } from '../services/service.index';
import { PanelcontrolComponent } from './panelcontrol/panelcontrol.component';
import { UsuariosComponent } from './catalogos/usuarios/usuarios.component';
import { EditarUsuarioComponent } from './catalogos/usuarios/editarusuario.component';
import { ListasComponent } from './configuracion/listas/listas.component';
import { CreaListaComponent } from './configuracion/listas/crealista.component';
import { ActualizaListaComponent } from './configuracion/listas/actualizalista.component';
import { OpcionesComponent } from './catalogos/opciones/opciones.component';
import { CreaOpcionComponent } from './catalogos/opciones/creaopcion.component';
import { ActualizaOpcionComponent } from './catalogos/opciones/actualizaopcion.component';
import { RolesComponent } from './catalogos/roles/roles.component';
import { CrearRolComponent } from './catalogos/roles/crearrol.component';
import { ActualizaRolComponent } from './catalogos/roles/actualizarol.component';
import { ParametrosgeneralesComponent } from './configuracion/parametrosgenerales/parametrosgenerales.component';
import { CreaParametroComponent } from './configuracion/parametrosgenerales/creaparametro.component';
import { ActualizaParametroComponent } from './configuracion/parametrosgenerales/actualizaparametro.component';
import { OpcionesusuariosComponent } from './catalogos/opcionesusuarios/opcionesusuarios.component';
import { AsignaOpcionesComponent } from './catalogos/opcionesusuarios/asignaopciones.component';
import { RolesopcionesComponent } from './catalogos/rolesopciones/rolesopciones.component';
import { AsignaOptionsRolesComponent } from './catalogos/rolesopciones/asignaopcionesrol.component';
import { PrivilegiosusuariosComponent } from './configuracion/privilegiosusuarios/privilegiosusuarios.component';
import { PrivilegioUsuarioComponent } from './configuracion/privilegiosusuarios/privilegiousuario.component';
import { CrearPrivilegioComponent } from './configuracion/privilegiosusuarios/crearprivilegio.component';
import { ActualizaPrivilegioComponent } from './configuracion/privilegiosusuarios/actualizaprivilegio.component';
import { CrearUsuarioComponent } from './catalogos/usuarios/crearusuario.component';
import { ServiciosexternosComponent } from './documentos/serviciosexternos/serviciosexternos.component';
import { ActualizaServicioExternoComponent } from './documentos/serviciosexternos/actualizaservicioexterno.component';
import { CreaServicioExternoComponent } from './documentos/serviciosexternos/creaservicioexterno.component';
import { TiposarchivosComponent } from './documentos/tiposarchivos/tiposarchivos.component';
import { ActualizaTipooArchivoComponent } from './documentos/tiposarchivos/actualizatipoarchivo.component';
import { CreaTipoArchivoComponent } from './documentos/tiposarchivos/creatipoarchivo.component';
import { DocumentoComponent } from './documentos/documento/documento.component';
import { CreaDocumentoComponent } from './documentos/documento/creadocumento.component';
import { ActualizaDocumentoComponent } from './documentos/documento/actualizadocumento.component';
import { TipoarchivodocumentoComponent } from './documentos/tipoarchivodocumento/tipoarchivodocumento.component';
import { CondicionesComponent } from './creditos/condiciones/condiciones.component';
import { CreaCondicionComponent } from './creditos/condiciones/creacondicion.component';
import { ActualizaCondicionComponent } from './creditos/condiciones/actualizacondicion.component';
import { PeriodospagoComponent } from './creditos/periodospago/periodospago.component';
import { CreaPeriodoPagoComponent } from './creditos/periodospago/creaperiodopago.component';
import { ActualizaPeriodoPagoComponent } from './creditos/periodospago/actualizaperiodopago.component';
import { CalificacioncreditosComponent } from './creditos/calificacioncreditos/calificacioncreditos.component';
import { CreacalificacioncreditosComponent } from './creditos/calificacioncreditos/creacalificacioncreditos.component';
import { ActualizaCalificacionCreditoComponent } from './creditos/calificacioncreditos/actualizacalificacioncredito.component';
import { TarifasComponent } from './creditos/tarifas/tarifas.component';
import { CreatarifaComponent } from './creditos/tarifas/creatarifa.component';
import { ActualizatarifaComponent } from './creditos/tarifas/actualizatarifa.component';
import { AprobarCreditosComponent } from './aprobar-creditos/aprobar-creditos.component';
import { AprobarCreditoComponent } from './aprobar-creditos/aprobar-credito.component';
import { AprobarCreditosGuard } from '../services/guards/aprobar-creditos.guard';
import { AdminGuard } from '../services/guards/admin.guard';
import { EmpleadosCadenasComponent } from '../pages/empleados-cadenas/empleados-cadenas.component';
import { ActualizarEmpleadosCadenasComponent } from '../pages/empleados-cadenas/actualizar-empleados-cadenas/actualizar-empleados-cadenas.component';
import { AltaCadenasComponent } from '../pages/empleados-cadenas/alta-cadenas/alta-cadenas.component';
import { MesaControlComponent } from './aprobar-creditos/mesa-control/mesa-control.component';
import { DispersionComponent } from './dispersion/dispersion.component';
import { ReporteConsultasBuroComponent } from './reporte-consultas-buro/reporteconsultasburo.component';
import { FlujoGuard } from '../services/guards/flujo.guard';
import { CreditAnalisisComponent } from './creditanalisis/creditanalisis.component';
import { AnalisisCreditosGuard } from '../services/guards/credit-analisis.guard';
import { AltaEmpleadosComponent } from './alta-empleados/alta-empleados.component';
import { RevisionDocumentosComponent } from './catalogos/revision-documentos/revision-documentos.component';
import { DoctoresComponent } from './catalogos/doctores/doctores.component';
import { EditarDoctorComponent } from './catalogos/doctores/editar-doctor.component';
import { CrearDoctorComponent } from './catalogos/doctores/crear-doctor.component';
import { ProductosComponent } from './catalogos/productos/productos.component';
import { EditarProductoComponent } from './catalogos/productos/editar-producto.component';
import { CrearProductoComponent } from './catalogos/productos/crear-producto.component';
import { CarrosComponent } from './catalogos/carros/carros.component';
import { EditarCarroComponent } from './catalogos/carros/editar-carro.component';
import { CrearCarroComponent } from './catalogos/carros/crear-carro.component';
import { CotizadorComponent } from './creditos/cotizador/cotizador.component';
import { RevisarCotizacionesComponent } from './aprobar-creditos/revisar-cotizaciones/revisar-cotizaciones.component';
import { EmpresasComponent } from './catalogos/empresas/empresas.component';
import { CrearEmpresaComponent } from './catalogos/empresas/crear-empresa.component';
import { VerusuariosComponent } from './catalogos/usuarios/verusuarios.component';
import { VerDoctoresComponent } from './catalogos/doctores/ver-doctores.component';
import { VerEmpresasComponent } from './catalogos/empresas/ver-empresas.component';
import { ReporteCotizacionesComponent } from './catalogos/reporte-cotizaciones/reporte-cotizaciones.component';
import { AsignarCotizacionesVendedorComponent } from './asignar-cotizaciones-vendedor/asignar-cotizaciones-vendedor.component';


const pagesRoutes: Routes = [
  {
      path: '',
      component: ControlPanelComponent,
      canActivate: [ LoginGuardGuard, AdminGuard ],
      children: [
        { path: 'panelcontrol',          component: PanelcontrolComponent },
        { path: 'panelcontrol/cotizador',          component: CotizadorComponent },
        { path: 'panelcontrol/revisionDocumentos',          component: RevisionDocumentosComponent },
        { path: 'panelcontrol/reporteCotizaciones',          component: ReporteCotizacionesComponent },
        { path: 'panelcontrol/automoviles',          component: CarrosComponent },
        { path: 'panelcontrol/automoviles/crearautomovil',          component: CrearCarroComponent },
        { path: 'panelcontrol/automoviles/editarautomovil/:id',          component: EditarCarroComponent },
        { path: 'panelcontrol/productos',          component: ProductosComponent },
        { path: 'panelcontrol/productos/crearproducto',          component: CrearProductoComponent },
        { path: 'panelcontrol/productos/editarproducto/:id',          component: EditarProductoComponent },
        { path: 'panelcontrol/verEmpresas',          component: VerEmpresasComponent },
        { path: 'panelcontrol/empresas',          component: EmpresasComponent },
        { path: 'panelcontrol/empresas/crearempresas',          component: CrearEmpresaComponent },
        { path: 'panelcontrol/empresas/crearempresas/:id',          component: CrearEmpresaComponent },
        { path: 'panelcontrol/verDoctores',          component: VerDoctoresComponent },
        { path: 'panelcontrol/doctores',          component: DoctoresComponent },
        { path: 'panelcontrol/doctores/creardoctor',          component: CrearDoctorComponent },
        { path: 'panelcontrol/doctores/editardoctor/:id',          component: EditarDoctorComponent },
        
        
        { path: 'panelcontrol/verUsuarios',          component: VerusuariosComponent },
        /* 
        { path: 'panelcontrol/usuarios',          component: UsuariosComponent, canActivate: [UsuariosPcGuard] },
        { path: 'panelcontrol/usuarios/crearusuario',          component: CrearUsuarioComponent, canActivate: [UsuariosPcGuard] },
        { path: 'panelcontrol/usuarios/editarusuario/:id',          component: EditarUsuarioComponent, canActivate: [UsuariosPcGuard] },
         */
        
        // { path: 'panelcontrol/usuarios/editarusuario/:id/:usuario',          component: EditarUsuarioComponent },
        // { path: 'panelcontrol/usuarios/editarusuario/:id/:usuario',          component: EditarUsuarioComponent },
        { path: 'panelcontrol/listas',          component: ListasComponent, canActivate: [ListasPcGuard] },
        { path: 'panelcontrol/listas/crearlista',          component: CreaListaComponent, canActivate: [ListasPcGuard] },
        { path: 'panelcontrol/listas/actualizalista/:id',          component: ActualizaListaComponent, canActivate: [ListasPcGuard] },
        { path: 'panelcontrol/opciones',          component: OpcionesComponent, canActivate: [OpcionesPcGuard] },
        { path: 'panelcontrol/opciones/creaopcion',          component: CreaOpcionComponent, canActivate: [OpcionesPcGuard] },
        { path: 'panelcontrol/opciones/actualizaopcion/:id',          component: ActualizaOpcionComponent, canActivate: [OpcionesPcGuard] },
        { path: 'panelcontrol/roles',          component: RolesComponent, canActivate: [RolesPcGuard] },
        { path: 'panelcontrol/roles/crearrol',          component: CrearRolComponent, canActivate: [RolesPcGuard] },
        { path: 'panelcontrol/roles/actualizarol/:id',          component: ActualizaRolComponent, canActivate: [RolesPcGuard] },
        { path: 'panelcontrol/parametrosgenerales',          component: ParametrosgeneralesComponent, canActivate: [ParametrosGeneralesPcGuard] },
        { path: 'panelcontrol/parametrosgenerales/creaparametro',          component: CreaParametroComponent, canActivate: [ParametrosGeneralesPcGuard] },
        { path: 'panelcontrol/parametrosgenerales/actualizaparametro/:id',          component: ActualizaParametroComponent, canActivate: [ParametrosGeneralesPcGuard] },
        { path: 'panelcontrol/opcionesusuarios',          component: OpcionesusuariosComponent, canActivate: [OpcionesUsuariosPcGuard] },
        { path: 'panelcontrol/opcionesusuarios/asignaopciones/:id',          component: AsignaOpcionesComponent, canActivate: [OpcionesUsuariosPcGuard] },
        { path: 'panelcontrol/rolesopciones',          component: RolesopcionesComponent, canActivate: [RolesOpcionesPcGuard] },
        { path: 'panelcontrol/rolesopciones/asignaopcionesrol/:id',          component: AsignaOptionsRolesComponent, canActivate: [RolesOpcionesPcGuard] },
        { path: 'panelcontrol/altaEmpleadosExcel',          component: AltaEmpleadosComponent},
        { path: 'panelcontrol/privilegiosusuarios',          component: PrivilegiosusuariosComponent, canActivate: [PrivilegiosUsuariosPcGuard] },
        { path: 'panelcontrol/privilegiosusuarios/privilegiosusuario/:id',          component: PrivilegioUsuarioComponent, canActivate: [PrivilegiosUsuariosPcGuard] },
        { path: 'panelcontrol/privilegiosusuarios/privilegiosusuario/creaprivilegio/:id',          component: CrearPrivilegioComponent, canActivate: [PrivilegiosUsuariosPcGuard] },
        { path: 'panelcontrol/privilegiosusuarios/privilegiosusuario/actualizaprivilegio/:idu/:idp',          component: ActualizaPrivilegioComponent, canActivate: [PrivilegiosUsuariosPcGuard] },
        { path: 'panelcontrol/serviciosexternos',          component: ServiciosexternosComponent, canActivate: [ServiciosExternosPcGuard] },
        { path: 'panelcontrol/serviciosexternos/creaserviciosexternos',          component: CreaServicioExternoComponent, canActivate: [ServiciosExternosPcGuard] },
        { path: 'panelcontrol/serviciosexternos/actualizaservicioexterno/:id',          component: ActualizaServicioExternoComponent, canActivate: [ServiciosExternosPcGuard] },
        { path: 'panelcontrol/tiposarchivos',          component: TiposarchivosComponent, canActivate: [TiposArchivosPcGuard] },
        { path: 'panelcontrol/tiposarchivos/creatipoarchivo',          component: CreaTipoArchivoComponent, canActivate: [TiposArchivosPcGuard] },
        { path: 'panelcontrol/tiposarchivos/actualizatipoarchivo/:id',          component: ActualizaTipooArchivoComponent, canActivate: [TiposArchivosPcGuard] },
        { path: 'panelcontrol/documento',          component: DocumentoComponent, canActivate: [DocumentoPcGuard] },
        { path: 'panelcontrol/documento/creadocumento',          component: CreaDocumentoComponent, canActivate: [DocumentoPcGuard] },
        { path: 'panelcontrol/documento/actualizadocumento/:id',          component: ActualizaDocumentoComponent, canActivate: [DocumentoPcGuard] },
        { path: 'panelcontrol/tipoarchivodocumento',          component: TipoarchivodocumentoComponent },
        { path: 'panelcontrol/condiciones',          component: CondicionesComponent, canActivate: [CondicionesPcGuard] },
        { path: 'panelcontrol/condiciones/creacondicion',          component: CreaCondicionComponent, canActivate: [CondicionesPcGuard] },
        { path: 'panelcontrol/condiciones/actualizacondicion/:id',          component: ActualizaCondicionComponent, canActivate: [CondicionesPcGuard] },
        { path: 'panelcontrol/periodospago',          component: PeriodospagoComponent, canActivate: [PeriodosPagoPcGuard] },
        { path: 'panelcontrol/empleadosCadenas',          component: EmpleadosCadenasComponent },
        { path: 'panelcontrol/empleadosCadenas/altaCadenas',          component: AltaCadenasComponent },
        { path: 'panelcontrol/empleadosCadenas/actualizarEmpleadosCadenas/:type/:id',          component: ActualizarEmpleadosCadenasComponent },
        { path: 'panelcontrol/periodospago/creaperiodopago',          component: CreaPeriodoPagoComponent, canActivate: [PeriodosPagoPcGuard] },
        { path: 'panelcontrol/periodospago/actualizaperiodopago/:id',          component: ActualizaPeriodoPagoComponent, canActivate: [PeriodosPagoPcGuard] },
        { path: 'panelcontrol/calificacioncreditos',          component: CalificacioncreditosComponent, canActivate: [CalificacionCreditosPcGuard] },
        { path: 'panelcontrol/revisarCotizaciones',          component: RevisarCotizacionesComponent },
        { path: 'panelcontrol/asignarCotizacionesVendedor',          component: AsignarCotizacionesVendedorComponent },
        { path: 'panelcontrol/aprobarCreditos',          component: AprobarCreditosComponent , canActivate: [AprobarCreditosGuard] },
        { path: 'panelcontrol/aprobarCredito/:token',          component: AprobarCreditoComponent , canActivate: [AprobarCreditosGuard] },
        { path: 'panelcontrol/aprobarCreditos',          component: AprobarCreditosComponent},
        // { path: 'panelcontrol/creditAnalisis',          component: CreditAnalisisComponent, canActivate: [AnalisisCreditosGuard]},
        { path: 'panelcontrol/creditAnalisis/:id',          component: CreditAnalisisComponent},
        { path: 'panelcontrol/aprobarCreditos/mesaControl',          component: MesaControlComponent , canActivate: [FlujoGuard] },
        { path: 'panelcontrol/calificacioncreditos/creacalificacioncredito',          component: CreacalificacioncreditosComponent, canActivate: [CalificacionCreditosPcGuard] },
        { path: 'panelcontrol/calificacioncreditos/actualizacalificacioncredito/:id',          component: ActualizaCalificacionCreditoComponent, canActivate: [CalificacionCreditosPcGuard] },
        { path: 'panelcontrol/tarifas',          component: TarifasComponent, canActivate: [TarifasPcGuard] },
        { path: 'panelcontrol/tarifas/creatarifa',          component: CreatarifaComponent, canActivate: [TarifasPcGuard] },
        { path: 'panelcontrol/tarifas/actualizatarifa/:id',          component: ActualizatarifaComponent, canActivate: [TarifasPcGuard] },
        { path: 'panelcontrol/dispersion',          component: DispersionComponent },
        { path: 'panelcontrol/reporteConsultasBuro',          component: ReporteConsultasBuroComponent },
          { path: '', redirectTo: '/home', pathMatch: 'full' }
      ]
  }
];


export const PANEL_ROUTES = RouterModule.forChild( pagesRoutes );
