// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  URL_SERVICIOS: 'https://fgdev.factorgfcleasing.com/api/v1',
  SECRET_KEY: 'c70d70102a9a493c9b9555dc665dc521',
  URL_BASE_TUIDENTIDAD: 'https://dev.tuidentidad.com/api/',
  AWS: {
    accessKeyId: "",
    secretAccessKey: "",
    region: '',
    folder: '',
    bucketNomina: ''
  },
  firebaseConfig: {
    apiKey: "AIzaSyAzrX_84BOqOyqAdvP2sbbrH_eiHQ18oXs",
    authDomain: "devarrendamientoau.firebaseapp.com",
    projectId: "devarrendamientoau",
    storageBucket: "devarrendamientoau.appspot.com",
    messagingSenderId: "714933702229",
    appId: "1:714933702229:web:a13e57cd4ccbdaae01cedc",
    measurementId: "G-GLY6CY3LQN"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
