import { Component, OnInit } from '@angular/core';
import { map } from 'jquery';
import { CreditosService, UsuarioService } from 'src/app/services/service.index';
import swal2 from 'sweetalert2';

@Component({
  selector: 'app-asignar-cotizaciones-vendedor',
  templateUrl: './asignar-cotizaciones-vendedor.component.html',
  styles: [
  ]
})
export class AsignarCotizacionesVendedorComponent implements OnInit {

  cotizaciones = [];
  cols = [

    { field: 'quote_folio', header: 'Folio' },
    { field: 'date', header: 'Fecha' },
    // { field: 'status', header: 'Estado' },
    { field: 'total', header: 'Total' },
    // { field: 'products', header: 'Productos' },
    { field: 'name', header: 'Nombre cliente' },
    { field: 'mail', header: 'Correo cliente' },
    { field: 'attached', header: 'Cotización' },

  ];
  options = {};

  constructor(private creditosService: CreditosService,
              private usuariosService: UsuarioService
  ) { }

  ngOnInit(): void {
    this.creditosService.getCotizacionesSinVendedor().subscribe(resp => {
      this.cotizaciones = JSON.parse(JSON.stringify(resp));
    });
    this.usuariosService.getUsuariosVendedores().subscribe(resp => {
      var options = {};
      $.map(resp,
        function(u) {
            options[u.id] = u.name;
        });
        this.options = options;
    });
  }

  swalSeleccionaVendedor(id){
    swal2.fire({
      title: "Selecciona un vendedor",
      input: "select",
      inputOptions: this.options,
      inputPlaceholder: "Opciones...",
      showCancelButton: true,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          this.asignaVendor(id, value);
        });
      }
    });
  }

  asignaVendor(id, vendor){
    this.creditosService.updateQuote(id, {user_vendor_id: vendor}).subscribe(resp => {
      swal2.fire({
        title: 'Cotización asignada',
        text: 'La cotización ha sido asignada al vendedor',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      });
      this.ngOnInit();
    }, err => {
      console.log(err);
      swal2.fire({
        title: 'Error',
        text: 'Ocurrió un error al asignar la cotización',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    });
  }

}
