import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { forkJoin, Observable, Subscription } from 'rxjs';
import swal2 from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { CreditosService } from '../../services/creditos/creditos.service';
import { ListasService } from '../../services/listas/listas.service';
import { AwsService } from '../../services/awsser/aws.service';
import { ReportesService } from '../../services/reportes/reportes.service';
import {DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
declare var $;
import * as fs from 'file-saver';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-aprobar-creditos',
  templateUrl: './aprobar-creditos.component.html',
  styleUrls: ['./aprobar-creditos.component.scss']
})
export class AprobarCreditosComponent implements OnInit {
  uploadForm: FormGroup;
  fileName = 'Layout.xlsx';
  facturas: any[] = [];
  url: any;
  file: any;
  datosdetalles: any[] = [];
  selectedSol: any[] = [];
  cols: any[] = [];
  solicitudes: any[] = [];
  estatussolicitudes: any[] = [];
  usuarios: any[] = [];
  idu: string;
  documentonulo: boolean;
  uploadURL: Observable<string>;
  uploadProgress: Observable<number>;
  showModal: boolean = false;
  showmodalds: boolean;
  idsolicitud: string;
  foliosolicitud: string;
  carpeta: string;
  nombrearchivosubido: string;

  calificaciones = [];
  requests = [];
  terms = [];
  paymentPeriods = [];
  intereses = 16;
  colDetallesCustomer = [];
  idRechazo = '';
  ngSelect = '';
  urlburo = '';

  creditos = [];
  rates = [];
  ivaRates = [];
  anuality_date = [];
  payments = [];
  documents = [];
  regimen_fiscal = [];
  regimen_fiscal_contribuyente = [];
  client_status = [];
  filtro = 'PA';
  credit_status = [];
  datosDoctor = {};
  datosEmpleado = {};
  resumen_creditos = [];
  creditos_buro = [];
  buro: string;
  analysis: string;
  buroStatus = false;
  analysisStatus = false;
  stepBuro = false;
  rfcBC = '';
  scoreBC = '';
  fechaReporte = '';
  saldosVencidos = '';
  accured_liabilities = '';
  anual_rate = '';
  balance_due = '';
  car_credit = '';
  cash_flow = 'Dato no capturado';
  created_at = '';
  analisis_credit_status = '';
  credit_type = '';
  customer_credit_id = '';
  customer_number = '';
  debt = '';
  debtnum = 0;
  debt_horizon = 'Dato no capturado'; 
  debt_rate = 'Dato no capturado';
  departamental_credit = '';
  discounts = 'Dato no capturado';
  id = '';
  last_key = '';
  lowest_key = '';
  mop_key = '';
  mortagage_loan = 'Dato no capturado';
  net_flow = 'Dato no capturado';
  net_flownum = 0;
  other_credits = '';
  overall_rate = '';
  payment_capacity = '';
  previus_credit = '';
  report_date = '';
  total_amount = '';
  total_cost = '';
  total_debt = '';
  ingresos_netos = "";
  ingresos_netosnum = 0;
  egresos_netos = '';
  egresos_netosnum = 0;
  gastos_familiares = '';
  gastos_familiaresnum = 0;
  renta_casa = '';
  renta_casanum = 0;
  creditos_CP = '';
  creditos_CPnum = 0;
  creditos_LP = '';
  creditos_LPnum = 0;
  periodo_pago = '';
  pdf:any;
  creditoSeleccionadoTabla = {};
  maritalStatus: any;
  lists = [];
  regimen_fiscal2 = [];
  uid: string;
  tipo = '';
  colAmortizacion = [
    {header: '#', field: 'pay_number', type: 'text'},
    {header: 'Fecha de pago', field: 'payment_date', type: 'date'},
    {header: 'Capital más intereses', field: 'payment', type: 'number'},
    {header: 'Adeudo actual', field: 'current_debt', type: 'number'},
    {header: 'Adeudo restante', field: 'remaining_debt', type: 'number'},
    {header: 'Renta', field: 'rent', type: 'number'},
    {header: 'IVA', field: 'iva', type: 'number'},
    {header: 'Mantenimiento', field: 'monthly_maintenance', type: 'number'},
    {header: 'Seguro', field: 'monthly_insurance', type: 'number'},
    {header: 'Comisión de asesor', field: 'adviser_comission', type: 'number'},
    {header: 'Intereses', field: 'interests', type: 'number'},
  ]
  creditosTotales = [];
  
  constructor(private _formBuilder: FormBuilder,
              public router: Router,
              private route: ActivatedRoute,
              private creditosService: CreditosService,
              private formBuilder: FormBuilder, private httpClient: HttpClient,
              private listasService: ListasService,
              private awsService: AwsService,
              private reportesService: ReportesService ,
              private sanitizer: DomSanitizer
              ) {}

  ngOnInit() {
    // alert('pestaña aprobados no va aqui, es la del flujo')
    this.uid = localStorage.getItem('uid');
    swal2.fire({
      title: 'Cargando',
      allowOutsideClick: false
    });
    swal2.showLoading();
    this.creditos = [];

    this.listasService.getLists().subscribe(resp => {
      resp['data'].forEach(list => {
        if(list['attributes'].domain === 'ANUALITY_DATE'){
          this.anuality_date.push(list['attributes']);
        }
        else if(list['attributes'].domain === 'PERSON_FISCAL_REGIME'){
          this.regimen_fiscal.push(list['attributes']);
        }
        else if(list['attributes'].domain === 'CONTRIBUTOR_FISCAL_REGIME'){
          this.regimen_fiscal_contribuyente.push(list['attributes']);
        }
        else if(list['attributes'].domain === 'CLIENT_STATUS'){
          this.client_status.push(list['attributes']);
        }
        else if(list['attributes'].domain === 'CREDIT_STATUS'){
          this.credit_status.push(list['attributes']);
        }
      });
    })

    this.creditosService.ext_rates().subscribe(resp => {
      resp['data'].forEach(rate => {
        if(rate.attributes.rate_type === 'TA'){
          this.rates.push(rate.attributes);
        }else if(rate.attributes.rate_type === 'IVA'){
          this.ivaRates.push(rate.attributes);
        }
      });
    })

    

    /* this.creditosService.getCondiciones()
      .subscribe(resp => {
        this.terms = resp;
        this.creditosService.getperiodosPagos()
          .subscribe(resp => {
            this.paymentPeriods = resp;
            this.paymentPeriods.push({id:'0', key:'Pago Fijo'});
            this.creditosService.getCredits().subscribe(credits => {
              console.log(credits)
              this.creditos = [];
              this.creditos = Object.values(credits).filter(c => c.status == this.filtro);
              this.creditos.forEach(c => {
                c['total_requested_iva'] = (parseFloat(c['total_requested']) * 1.16).toString();
              })
            })
          })
        }) */

        var observableTerms = this.creditosService.getCondiciones();
        var observablePP = this.creditosService.getperiodosPagos();
        var observableCredits = this.creditosService.getCredits();
        forkJoin([observableTerms, observablePP, observableCredits]).subscribe(([terms, paymentPeriods, credits]) => {
          this.terms = terms;
          this.paymentPeriods = paymentPeriods;
          this.creditosTotales = Object.values(credits);
          this.creditosTotales.forEach(c => {
            c['total_requested_iva'] = (parseFloat(c['total_requested']) * 1.16).toString();
          })
          this.cambiarFiltro('PA')
        })

    swal2.close();

    this.uploadForm = this.formBuilder.group({
      profile: ['']
    });

    const estatus: any = document.getElementById('estatus');

    this.selectedSol = [];

    this.idu = localStorage.getItem('uid'); 

    this.cols = [

      {field: 'credit_folio', header: 'Folio'},
      // {field: 'date', header: 'Fecha cotización'},
      {field: 'start_date', header: 'Fecha inicial'},
      {field: 'name', header: 'Nombre'},
      {field: 'total_requested_iva', header: 'Total'},
      {field: 'term', header: 'Plazo'},
      {field: 'payment_period', header: 'Periodicidad'},
      // {field: 'capital', header: 'Capital'},
      {field: 'inicial_payment', header: 'Pago inicial'},
      {field: 'fixed_payment', header: 'Pago fijo'},
      {field: 'iva', header: 'IVA'},
      // {field: 'insurance', header: 'Seguro'},
      {field: 'interests', header: 'Intereses totales'},
      // {field: 'iva_percent', header: 'IVA %'},
      {field: 'rate', header: 'Tasa'},
      // {field: 'residual_value', header: 'Valor residual'},
      {field: 'total_debt', header: 'Deuda total'},
      {field: 'vendor', header: 'Vendedor'},
      {field: 'attached', header: 'Cotización'},

    ];

    this.listasService.getLists().subscribe(lists => {
      lists['data'].forEach(list => {
        this.lists.push(list.attributes);
      });
    this.maritalStatus = this.lists.filter(p => p.domain === 'PERSON_MARTIAL_STATUS');
    this.regimen_fiscal2 = this.lists.filter(p => p.domain === 'CONTRIBUTOR_FISCAL_REGIME');
    })
  }

  async detalleCliente(rowData) {
    this.datosDoctor = {};
    this.creditosService.traer_customer(rowData.id).subscribe(resp => {
      console.log(resp[0])
      this.datosDoctor = resp[0];
      if (this.datosDoctor['customer']['contributor']['person']) {
        this.tipo = 'person'
      }
      if (this.datosDoctor['customer']['contributor']['legal_entity']) {
        this.tipo = 'legal_entity';
      }
      this.documents = this.datosDoctor['quote']['user_customer']['user_documents'];
      this.showmodalds = true;
    });
    
    this.creditosService.tiene_analisis(rowData).subscribe(analysis => {
      this.analysisStatus = analysis.status;
      if (this.analysisStatus){
        this.analysis = analysis.credit_analysis[0];
        this.mop_key = this.analysis['mop_key'];
        this.last_key = this.analysis['last_key'];
        this.lowest_key = this.analysis['lowest_key'];
        this.analisis_credit_status = this.analysis['credit_status'];
      }else {
        console.log("NO TIENE ANALISIS")
      }

    })

    this.creditosService.tiene_buro(rowData).subscribe(buro => {
      console.log('buro', buro)
      this.buroStatus = buro.status;
      if (this.buroStatus){
        this.buro = buro.credit_bureau;
        // console.log("BURO")
        // console.log(this.buro[0]['bureau_report']['results'][1]['response']['return']['Personas']['Persona'][0]['Nombre']['RFC'])
        this.fechaReporte = this.buro['bureau_report']['respuesta']['updatedAt'];

        this.pdf = this.buro['extra1']
        this.urlburo = `https://docs.google.com/viewer?url=${this.pdf}&embedded=true`

        this.rfcBC = this.buro['bureau_report']['respuesta']['persona']['nombre']['RFC'];
        this.saldosVencidos = this.buro['bureau_report']['respuesta']['persona']['resumenReporte'][0]['totalSaldosVencidosPagosFijos']
        // let calificacionBC = this.buro[0]['bureau_report']['results'][1]['response']['return']['Personas']['Persona'][0]['ScoreBuroCredito']['ScoreBC'][0]['ValorScore']

        // console.log("this.buro[0]['bureau_report']['results'][1]['response']['return']['Personas']['Persona'][0]['Cuentas']['Cuenta']")
        // console.log(this.buro[0]['bureau_report']['results'][1]['response']['return']['Personas']['Persona'][0]['Cuentas']['Cuenta'])
        this.resumen_creditos = this.buro['bureau_report']['respuesta']['persona']['cuentas']
        this.creditos_buro =this.get_ur_credits(this.resumen_creditos)
      }else {
        // stepBuro
        console.log("NO TIENE BURO")
        // document.getElementById('guardarAnalisisBtn').setAttribute("disabled", "disabled");

      }

    });
    
    /* this.reportesService.getRegistrationInfo(rowData.id).subscribe(info => {
      this.datosEmpleado = info[0];
      this.datosEmpleado['dependientes'] = parseInt(this.datosEmpleado['senior_dependents']) + parseInt(this.datosEmpleado['minior_dependents'])
      this.datosEmpleado['martial_status_value'] = this.maritalStatus.find(m => m.key == this.datosEmpleado['martial_status']).value;
      this.datosEmpleado['fiscal_regime_value'] = this.regimen_fiscal2.find(r => r.key == this.datosEmpleado['fiscal_regime']).value;
      this.datosEmpleado['business_name'] = rowData.customer.business_name;
      if(this.datosEmpleado['salary_period'] == 'Quincenal'){
        this.datosEmpleado['month_salary'] = ((parseFloat(this.esNulo(this.datosEmpleado['salary'])) + parseFloat(this.esNulo(this.datosEmpleado['ontime_bonus'])) +  parseFloat(this.esNulo(this.datosEmpleado['assist_bonus'])) +  parseFloat(this.esNulo(this.datosEmpleado['food_vouchers'])) ) * 2).toFixed(2);
      }else if(this.datosEmpleado['salary_period'] == 'Semanal'){
        this.datosEmpleado['month_salary'] = (( parseFloat(this.esNulo(this.datosEmpleado['salary'])) + parseFloat(this.esNulo(this.datosEmpleado['ontime_bonus'])) +  parseFloat(this.esNulo(this.datosEmpleado['assist_bonus'])) +  parseFloat(this.esNulo(this.datosEmpleado['food_vouchers']))  ) * 4.33).toFixed(2);
      }
      this.ingresos_netos = this.formatNumber(this.esNulo(parseFloat(this.datosEmpleado['month_salary']).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
      this.ingresos_netosnum = parseFloat(this.datosEmpleado['month_salary'])
      this.awsService.getUserDocuments(rowData.customer.id_contribuyente).subscribe(docs => {
        this.documents = [];
        docs['data'].forEach(doc => {
          if(doc.attributes.name == "Comprobantes de ingresos del empleado"){
            var arrayUrl = doc.attributes.url.split('*');
            arrayUrl.forEach(url => {
              if(url != ''){
                let params = {
                  contributor_id: doc.attributes.contributor_id,
                  created_at: doc.attributes.created_at,
                  extra1: null,
                  extra2: null,
                  extra3: null,
                  file_type_document_id: doc.attributes.file_type_document_id,
                  id: doc.attributes.id,
                  name: doc.attributes.name,
                  notes: null,
                  status: doc.attributes.status,
                  updated_at: doc.attributes.updated_at,
                  url: url
                }
                let variable = doc.attributes;
                variable.url = url;
                this.documents.push(params);
              }
            });
          }else{
            this.documents.push(doc.attributes);
          }
        });
        if(this.buroStatus){
          if(this.buro[0]['extra1'] != null){
            this.documents.push({'url': this.buro[0]['extra1'], 'name': 'Reporte Buró de Crédito'});
          }
        }
          if(this.datosEmpleado['extra1'] !== null){
            this.documents.push({'url': this.datosEmpleado['extra1'], 'name': 'Expediente'});
          }        
      })
    }); */

  }

  onStepBuro(){
    this.stepBuro = false;
  }

  cleanURL(oldURL: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(oldURL);
  }

  cambiarFiltro(status){
    this.filtro = status;
    /* setTimeout(() => {
      document.getElementById('reset').click();
    }, 1800);
    this.ngOnInit(); */
    this.creditos = this.creditosTotales.filter(c => c.status == this.filtro)
  }

  guardar(id){
    localStorage.setItem('customer_credit', id);
  }

  rechazar(id){
    this.creditosService.updateCredit(id, {status: 'RZ'}).subscribe(resp => {
      swal2.fire({
        title: 'Exito', 
        text: 'Crédito rechazado', 
        icon: 'success',
        showConfirmButton: true,
        allowOutsideClick: false
      }).then(res => {
        this.ngOnInit();
      });
    }, err => {
      console.log(err);
      swal2.fire('Error', 'Algo salió mal', 'info');
    })
  }

  crearProject(info){
    swal2.showLoading()
    this.creditosService.updateCredit(info.id, {status: 'RE'}).subscribe(resp => {
      let date = new Date();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      let start_date;

      if(month < 10){
        start_date =  `${year}-0${month}-${day}`;
      }else{
        start_date = `${year}-${month}-${day}`;
      }
      const params = {};
      const customer_credit = {
        total_requested: info.total_requested,
        status: 'PR',
        start_date: start_date,
        customer_id: info.customer_id,
        rate: info.rate,
        user_id: localStorage.getItem('uid'),
        iva_percent: info.iva_percent,
        currency: info.currency
        // term_id: info.term_id,
        // payment_period_id: info.payment_period_id,
      }
      if(info.term_id === '0'){
        params['payment_amount'] = info.fixed_payment;
      }else {
        customer_credit['term_id'] = info.term_id;
        // params['payment_period_id'] = info.payment_period_id;
      }
      if(info.hasOwnProperty('anualidad')){
        customer_credit['anuality'] = info.anualidad;
        if(info.hasOwnProperty('anuality_date')){
          customer_credit['anuality_date'] = info.anuality_date;
        }else{
          swal2.fire('Faltan datos', 'Seleccione una fecha para el pago de la anualidad', 'info');
          return;
        }
      }
      params['customer_credit'] = customer_credit;
      // console.log(params);
      // SE ENVIAN LOS CORREOS AL COMITE Y EMPRESA PARA QUE FIRMEN/ACEPTEN EL CREDITO
      this.creditosService.customerCredit(params)
        .subscribe(resp => {
          swal2.fire({
            title: 'Operación correcta', 
            text: 'Propuesta enviada', 
            icon: 'success',
            allowOutsideClick: false,
            showConfirmButton: true
          }).then(resp => {
            if(resp.value){
              this.ngOnInit();

            }
          });
        }, err => {
          console.log('err', err);
          try{
            swal2.fire('Hubo un problema', err.error.errors[0], 'info');
          }catch{
            swal2.fire('', 'Hubo un problema', 'info');
          }
        })
    }, err => {
      console.log('error', err);
      swal2.fire('', 'Hubo un problema', 'info');
    })
  }

  //------------------------------------------------------------

  get_ur_credits(credits){ 
    var ur_credits = {mop: 'UR', cuentas_abiertas: 0, limite_abiertas: 0, maximo_abiertas: 0, saldo_actual: 0,
                saldo_vencido: 0, pago_realizar: 0, cuentas_cerradas: 0, limite_cerradas: 0, maximo_cerradas: 0,
                saldo_cerradas: 0, monto_cerradas: 0}

    var credits_01 = {mop: '01',cuentas_abiertas: 0, limite_abiertas: 0, maximo_abiertas: 0, saldo_actual: 0, saldo_vencido: 0,
    pago_realizar: 0, cuentas_cerradas: 0, limite_cerradas: 0, maximo_cerradas: 0, saldo_cerradas: 0,
    monto_cerradas: 0}


    var all_credits = []


    credits.forEach(credit =>{
      if(credit['FechaCierreCuenta'] && credit['FormaPagoActual'] === 'UR'){
        ur_credits['cuentas_cerradas'] += 1
        ur_credits['limite_cerradas'] += parseInt(credit['LimiteCredito'] ? credit['LimiteCredito'] : 0)
        ur_credits['maximo_cerradas'] += parseInt(credit['CreditoMaximo'])
        ur_credits['saldo_cerradas'] += parseInt(credit['SaldoActual'])
        ur_credits['monto_cerradas'] += parseInt(credit['MontoPagar'])
      }else if (credit['FechaCierreCuenta'] && credit['FormaPagoActual'] === '01'){
        credits_01['cuentas_cerradas'] += 1
        credits_01['limite_cerradas'] += parseInt(credit['LimiteCredito'] ? credit['LimiteCredito'] : 0)
        credits_01['maximo_cerradas'] += parseInt(credit['CreditoMaximo'])
        credits_01['saldo_cerradas'] += parseInt(credit['SaldoActual'])
        credits_01['monto_cerradas'] += parseInt(credit['MontoPagar'])
      }else if (!credit['FechaCierreCuenta'] && credit['FormaPagoActual'] === 'UR'){
        ur_credits['cuentas_abiertas'] += 1
        ur_credits['limite_abiertas'] += parseInt(credit['LimiteCredito'] ? credit['LimiteCredito'] : 0)
        ur_credits['maximo_abiertas'] += parseInt(credit['CreditoMaximo'])
        ur_credits['saldo_actual'] += parseInt(credit['SaldoActual'])
        ur_credits['saldo_vencido'] += parseInt(credit['SaldoVencido'])
        ur_credits['pago_realizar'] += parseInt(credit['MontoPagar'])
      }else if (!credit['FechaCierreCuenta'] && credit['FormaPagoActual'] === '01'){
        credits_01['cuentas_abiertas'] += 1
        credits_01['limite_abiertas'] += parseInt(credit['LimiteCredito'] ? credit['LimiteCredito'] : 0)
        credits_01['maximo_abiertas'] += parseInt(credit['CreditoMaximo'])
        credits_01['saldo_actual'] += parseInt(credit['SaldoActual'])
        credits_01['saldo_vencido'] += parseInt(credit['SaldoVencido'])
        credits_01['pago_realizar'] += parseInt(credit['MontoPagar'])
      }
    });

    all_credits.push(ur_credits)
    all_credits.push(credits_01)

    return all_credits
  }

  esNulo (dato){
    if(dato == null){
      console.log("dato NULO")
      console.log(dato)
      return 0;
    }else{
      return dato;
    }
  }

  showModalf(rowData) {
    var id = rowData.id;
    this.creditoSeleccionadoTabla = rowData;
    this.payments = [];
    this.creditosService.customerCreditPayments(id).subscribe(pays => {
      pays['data'].forEach(p => {
/*         this.payments.push(p['attributes']); */
          const payment = p['attributes'];/* borrar */
          if(payment.commission == null ){
            payment.commission = 0
          }
          if(payment.insurance == null ){
            payment.insurance = 0
          }
/*           payment.insurance = 100; // Valor fijo de seguro
          payment.commission = 50; // Valor fijo de comisión */
          this.payments.push(payment);/* borrar */
        })
      })
      // CODIGO DEL ENDPOINT QUE SE HABIA HECHO, SE REGRESO AL ORIGINAL, ESTA ARRIBA
    /* this.creditosService.customerCreditPaymentsFilterDays(id).subscribe(pays => {
      var pagos = Object.keys(pays);
      var pagoDiferido = false;
      pagos.forEach(p => {
        const payment = pays[p];
        if(payment['adviser_comission'] == null ){
          payment['adviser_comission'] = 0
        }
          if(payment['monthly_insurance'] == null ){
            payment['monthly_insurance'] = 0
          }
          if(payment['monthly_maintenance'] == null ){
            payment['monthly_maintenance'] = 0
          }
          if(payment['rent'] == null ){
            payment['rent'] = 0
          }
          this.payments.push(payment);
        });
        if(this.payments.find(p => p['pay_number'] == 2) == undefined){
          pagoDiferido = true;
        }
        this.payments.forEach(payment => {
          if(pagoDiferido && payment['pay_number'] != 1){
            payment['pay_number'] = payment['pay_number'] - 1;
          }
        });
    }); */
    
    this.showModal = true; // Show-Hide Modal Check
  }

  hideModal() {
    this.showModal = false;
  }  

  show(datarow) {
    this.showModal = true; // Show-Hide Modal Check
    // console.log(datarow);
    this.idsolicitud = datarow.id;
    this.foliosolicitud = datarow.folio;
    // return '2';
  }

  hideds() {
    this.showmodalds = false;
  }  

  formatNumber(n) {
    // format number 1000000 to 1,234,567
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }


  exportexcel() {
    this.fileName = "Layout" + '.xlsx';
    const Excel = require('exceljs');
    let workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet('base');
    let titulo = ['PLANTILLA DE PAGOS PROGRAMADOS'];
    worksheet.addRow(titulo);
    worksheet.mergeCells(1, 1, 1, 2);
    let pagos = ['No. De pagos', this.payments.length]
    worksheet.addRow(pagos);
    let header=[];
    this.colAmortizacion.forEach(c => {
      header.push(c.header);
    })
    worksheet.addRow(header);
    for (const prop in this.payments) {
      var datePipe = new DatePipe('en-US');
      let contenido = [];
      this.colAmortizacion.forEach(c => {
        if(c.type == 'date'){
          var fecha = datePipe.transform(this.payments[prop][c.field], 'dd/MM/yyyy');
          contenido.push(fecha);
        }else{
          contenido.push(this.payments[prop][c.field])
        }
      })
      worksheet.addRow(contenido);
    } 
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: '.xlsx' });
      const file = blob;
      fs.saveAs(blob, this.fileName);
  });
  }

}
